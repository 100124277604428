export default class LoginPro{
    static initFromDom(){

        $body.on("click","[href='#logout']",function(e){
            e.preventDefault();
            PovApi.action("logout",{}).then(
                function(e){
                    window.scrollTo(0, 0);
                    document.location.reload(true);
                }
            ).catch(
                function(b){
                    window.scrollTo(0, 0);
                    document.location.reload(true);
                }
            );
        });

        //affiche le form lost pwd
        $body.on("click",".js-login-pro [href='#show-lost-pwd']",function(e){
            e.preventDefault();
            let $form=$(".form-lost-pwd");
            $form.removeClass("hidden");
            TweenLite.to(window, 0.5, {scrollTo:{y:$form.offset().top - 130}});
        });

        //css quand champ obligatoire est rempli
        $body.on("input",".js-login-pro [data-is-mandatory='error']",function(e){
            if($(this).val()){
                $(this).attr("data-is-mandatory","");
            }
        });
        //envoie d'un des formulaires
        $body.on("submit",".js-login-pro form",function(e){



            e.preventDefault();
            let $form=$(this);

            //
            let $message=$form.find(".message");
            function hideMessage(){
                $message.addClass("hidden");
                $message.removeClass("success");
                $message.removeClass("error");
                $message.text("");
            }
            function message(isError=false,message){
                $message.removeClass("hidden");
                $message.removeClass("success");
                $message.removeClass("error");
                if(isError){
                    $message.addClass("error");
                }else{
                    $message.addClass("success");
                }
                $message.text(message);
            }
            let action=$form.find("[name='action']").val();

            //teste les champs obligatoires
            let $mandatory=$form.find("[data-is-mandatory]");
            let mandatoryError=false;
            $mandatory.each(function(){
                if(!$(this).val()){
                    mandatoryError=true;
                    $(this).attr("data-is-mandatory","error");
                }
            });
            if(mandatoryError){
                message(true,LayoutVars.translations["Login erreur Veuillez saisir tous les champs obligatoires"]);
            }else{
                message(false,LayoutVars.translations["Veuillez patienter"]);

                let formData={};
                $form.find("[name]").each(function(){
                    formData[$(this).attr("name")]=$(this).val();
                });

                PovApi.action(action,formData).then(
                    function(e){
                        if(e.success){
                            message(false,e.messages.join(". "));
                            if(e.json.todo === "refresh"){
                                document.location.reload(true);
                            }
                        }else{
                            message(true,e.errors.join(". "));
                        }
                    }
                ).catch(
                    function(b){
                        console.error("b",b)
                    }
                );
                /*
                PovApi.action("formContact",
                    {
                        nom:nom,
                        prenom:prenom,
                        email:email,
                        phone:phone,
                        numeroadherent:numeroadherent,
                        message:message
                    }
                ).then(
                    function(e){
                        if(e.success){
                            $success.removeClass("hidden");
                            $success.text(LayoutVars.translations["Merci form contact"]);
                        }else{
                            $success.addClass("hidden");
                            $erreur.removeClass("hidden");
                            $erreur.text(e.errors.join(". "));
                        }
                    }
                ).catch(
                    function(b){
                        console.error("b",b)
                    }
                );
                */
            }


        })
    }
}