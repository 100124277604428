export default class FiltresEcoles{
    static initListeners(){
        $body.on("click","[data-filtre-ecole]",function(e){
            let wasActive=$(this).is(".active");
            if(!wasActive){
                FiltresEcoles.setActive($(this));
            }else{
                $("[data-filtre-ecole]").removeClass("active");
            }
            FiltresEcoles.display();
        });

    }

    /**
     * Rend un filtre actif
     * @param $el
     */
    static setActive($el){
        $("[data-filtre-ecole]").removeClass("active");
        $el.addClass("active");
    }
    static display(){

        FiltresEcoles.current=$("[data-filtre-ecole].active").attr("data-filtre-ecole");
        if(FiltresEcoles.current===undefined){
            FiltresEcoles.current="";
        }
        console.log(" Afficher les écoles "+FiltresEcoles.current);
        //place l'attribut à modifier sur #main pour que ce
        $("#main-content:first-child").attr("data-filter-ecole-only",FiltresEcoles.current);

        $body.trigger("EVENT_FILTRE_ECOLES");
    }

    /**
     * Désactive les filtres
     */
    static resetAll(){
        $("[data-filtre-ecole]").removeClass("active");
        FiltresEcoles.display();
    }
}