import PovApi from "../../../vendor/davidmars/pov-2018/_src/PovApi";

let MarkerClusterer = require('./markerclusterer');

export default class PKMap{
    constructor($main){

        this.markersById={};

        this.infowindow = new google.maps.InfoWindow({
            content: "..."
        });


        let me=this;
        let lat=Number($main.attr("data-lat"));
        let lng=Number($main.attr("data-lng"));
        let zoom=Number($main.attr("data-zoom"));
        let id=$main.attr("data-id");

        let center = {lat: lat, lng: lng};
        let map=this.map = new google.maps.Map($main[0], {
            zoom: zoom,
            center: center
        });
        let marker;
        let ecole;
        let markers=[];
        for(let i=0;i<LayoutVars.mapEcoles.length;i++){
                ecole=LayoutVars.mapEcoles[i];
                marker = new google.maps.Marker(
                    {
                        position: {lat: Number(ecole.lat), lng: Number(ecole.lng)},
                        map: map,
                        id:ecole.id,
                        icon:LayoutVars.fmkHttpRoot+'/dist/clusters/marker.png',
                        width: 30,
                        height:30,
                        title:ecole.name,
                        ecoleData:ecole
                });

            marker.addListener('click', function() {
                me.openPopin(this);
            });

            markers.push(marker);
            me.markersById[ecole.id]=marker;
        }

        if(id){
            me.openPopin(me.markersById[id]);
        }

        google.maps.event.addListener(me.infowindow, 'domready', function() {


            //pose des classes css sur les éléments de la fenêtre
            /*
            var iwOuter = $('.gm-style-iw');
            var iwBackground = iwOuter.prev();
            console.log("iwOuter",iwOuter)
            console.log("iwBackground",iwBackground)
            iwBackground.children(':nth-child(2)').addClass("ee-shadow");
            iwBackground.children(':nth-child(4)').addClass("ee-bg");
            iwBackground.parent("div").addClass("ee-papa");
            iwBackground.parent("div").parent("div").addClass("ee-pepe")
            iwOuter.next().addClass("ee-close");
            iwOuter.prev().addClass("ee-triangle");

             */

        });


        //var markerCluster = new MarkerClusterer(map, markers, mcOptions);

        let markerCluster = new MarkerClusterer(map, markers,{
            imagePath: LayoutVars.fmkHttpRoot+'/dist/clusters/pk',
            maxZoom:8,
            ignoreHidden: true,
            styles:[
                {
                    url:LayoutVars.fmkHttpRoot+'/dist/clusters/pk1.png',
                    width: 56,
                    height:55,
                    fontFamily:"Roboto",
                    fontWeight:"400",
                    textSize:9,
                    textColor:"#ffffff"
                }
            ]
            }
        );
        markerCluster.setIgnoreHidden(true);

        $body.on("EVENT_FILTRE_ECOLES",function(e){
            let prop=$("[data-filter-ecole-only]").attr("data-filter-ecole-only");
            if(prop){
                //prop
                for(let i=0;i<LayoutVars.mapEcoles.length;i++){
                    ecole=LayoutVars.mapEcoles[i];
                    let m=me.markersById[ecole.id];
                    if(prop && !ecole[prop] || ecole[prop]==="0"){
                        m.setVisible(false);
                    }else{
                        m.setVisible(true);
                    }
                }
            }else{
                //toutes visibles
                for(let i=0;i<LayoutVars.mapEcoles.length;i++){
                    ecole=LayoutVars.mapEcoles[i];
                    let m=me.markersById[ecole.id];
                    m.setVisible(true);
                }
            }
            markerCluster.repaint();
        })
    }

    openPopin(marker){
        let me =this;
        me.infowindow.open(me.map,marker);
        PovApi.getView("map/infowindow-ecole",null,{uid:"ecole-"+marker.id},function(r){
            me.infowindow.setContent(r.html());
        });

    }

    static initFromDom(){
       $("[data-pk-map='']").each(function(){
            $(this).attr("data-pk-map","init");
            new PKMap($(this));
       });

    }
}
