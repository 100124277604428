import DataSocialShareClick from "data-social-share-click/data-social-share-click";
import PKMap from "./map/PKMap";
import Quizz from "./quizz/Quizz";
import FiltresEcoles from "./ecoles/FiltresEcoles";
import FormContact from "./blocks/FormContact";
import LoginPro from "./login-pro/LoginPro";

require("./site.less");

/**
 * Gère à peu près tout dans le site
 */
export default class Site{

    constructor() {
        /**
         *
         * @type {Site}
         */
        let me = this;
        me._initListeners();
        //---------------------go------------------------------------------
        me.resizeStage();
        me.onDomChange();
        Site.navActive();
    }

    /**
     *
     * @private
     */
    _initListeners() {

        let me=this;

        let socialShares=new DataSocialShareClick();
        socialShares.listenClicks();
        FiltresEcoles.initListeners();
        require("./layout/NavMenu");
        NavMenu.__init();
        require("./blocks/FormContact");
        FormContact.initFromDom();
        LoginPro.initFromDom();


        $body.on("change","select.open-url",function(e){
            window.open($(this).val(),"assurance")
        });

        //ferme le menu quand on change d'url
        $body.on(EVENTS.HISTORY_CHANGE_URL,function(){
            //stope en attendant que la transition soit finie
            PovHistory.readyToinject=false;
            //dit qu'on est prêt à afficher la page (s'assure qu'on reste au moins une seconde sur l'écran de transition)
            setTimeout(function(){
                PovHistory.readyToinject=true;
            },100);
            NavMenu.close();
        });

        //changement d'url et HTML injecté
        $body.on(EVENTS.HISTORY_CHANGE_URL_LOADED_INJECTED,function(){
            me.onDomChange();
            //scroll top
            $(window).scrollTop(0);
            Site.navActive();
            FiltresEcoles.resetAll();
            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId){
                //hit google analytics
                gtag('config', LayoutVars.googleAnalyticsId, {'page_path': location.pathname});
            }
        });

        STAGE.on(EVENTS.RESIZE,function(){
            me.resizeStage();
        });
        $body.on(Pov.events.DOM_CHANGE,function(){
            me.onDomChange();
        });

        $body.on("mousedown","[data-stat-event-category]",function(e){
            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId) {
                gtag('event',$(this).attr("data-stat-event-action") ,{
                    'event_category': $(this).attr("data-stat-event-category"),
                    'event_label': $(this).attr("data-stat-event-label"),
                    //'value':""
                });
            }
        })

    }

    /**
     * Selectionne / déselectionne l'item de nav de la page en cours
     */
    static navActive(){
        $("[data-href-uid]").removeClass("active");
        $("[data-href-uid='"+PovHistory.currentPageInfo.uid+"']").addClass("active");
    }


    /**
     * Adapte des élements à l'écran
     */
    resizeStage(){
        //
    }

    /**
     * Initialisations d'objets dom
     */
    onDomChange(){
       PKMap.initFromDom();
       Quizz.initFromDom();

        FB.XFBML.parse();

       //masque le filtre écoles en fonction du scroll
        let options = {
            root: null,
            rootMargin: '0px'
        };
        let onChange=function(entries, observer){
            let $filtres=$(".ecoles-filtres");

            entries.forEach(entry => {
                let active=entry.isIntersecting;
                if(active){
                    $filtres.addClass("hidden");
                }else{
                    $filtres.removeClass("hidden");
                }
            });
        };

        $(".filtres-ecoles-limit").each(function(){
            let observer = new IntersectionObserver(onChange, options);
            observer.observe($(this)[0]);
        });

    }



}