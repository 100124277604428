import PKMap from "../map/PKMap";

export default class Quizz{
    constructor($main){
        let me=this;
        this.$main=$main;
        this.step=-1;
        this.note=0;
        this.$steps=$main.find(".step");
        this.$intro=$main.find(".intro");
        this.$end=$main.find(".end");
        this.$noteFinale=$main.find(".note-finale");

        $main.on("click",".answer",function(e){
            $(this).toggleClass("active");
            me.testNext();
        });
        $main.on("click",".step button.ok",function(e){
           me.validateStep($(this).closest(".step"));
        });
        $main.on("click",".step button.next",function(e){
           me.nextStep();
        });
        $main.on("click",".intro button",function(e){
           me.start();
        });
    }

    /**
     * Retourne la $step courrante
     * @return {jQuery.fn.init|jQuery|HTMLElement}
     */
    $currentStep(){
        return $(this.$main.find("[data-step='"+this.step+"'].active"));
    }

    testNext(){
        if( this.$currentStep().find(".answer.active").length){
            this.$currentStep().addClass("next-btn-ok");
        }else{
            this.$currentStep().removeClass("next-btn-ok");
        }
    }
    start(){
       this.$intro.addClass("past");
       this.nextStep()
    }
    nextStep(){
        TweenMax.to(window, 0.2, {scrollTo:{y:$main.find(".screens").offset().top-150, x:0}});
        this.$main.find("[data-step='"+this.step+"']").addClass("past");
        this.step++;
        this.$main.find("[data-step='"+this.step+"']").addClass("active");
        this.$main.find(".progression .bar>i").css("width",String(100/this.$steps.length*this.step)+"%");
        this.$main.find(".progression .js-step").text(this.step+1);
        this.$main.attr("data-step",this.step);
        if(this.step>=this.$steps.length){
            this.resultat();
        }
    }
    validateStep($step){
        let me=this;
        let total=0;
        let $resultat=$step.find(".resultat");
        let $answers=$step.find(".answer");
        $answers.each(function(){
            //si réponse cochée alors qu'il fallait
            if($(this).is("[data-correct='1'].active")){
                total++;
            }
            //si réponse pas cochée alors qu'il fallait pas
            if($(this).is("[data-correct='']:not(.active)")){
                //total++;
            }
            //si réponse cochée alors qu'il fallait pas
            if($(this).is("[data-correct=''].active")){
                total-=0.5;
            }
        });
        total=Math.max(total,0);
        let note=(1/$answers.filter("[data-correct='1']").length)*total;
        this.note+=note;
        //$resultat.text(""+total+" / "+$answers.length+" note = "+note+ " "+Quizz.noteToText(note));
        $resultat.text(Quizz.noteToText(note));
        if(note<0.5){
            $resultat.addClass("bad");
        }
        console.log(note);
        $step.addClass("done");
    }
    resultat(){
        this.$end.addClass("active");
        this.$noteFinale.find(".note").text(""+Math.round(this.note)+"/"+this.$steps.length);
        this.$noteFinale.find(".text").text(Quizz.noteFinaleToText(this.note));
        if(this.note<this.$steps.length/2){
            this.$noteFinale.addClass("bad");
        }
        this.$main.attr("data-step","end");
    }
    static noteToText(note){
        switch (true){
            case note===0:
                return LayoutVars.translations["Quizz note question 1"];
            case note<0.5:
                return LayoutVars.translations["Quizz note question 2"];
            case note<1:
                return LayoutVars.translations["Quizz note question 3"];
            default:
                return LayoutVars.translations["Quizz note question 4"];
        }
    }
    static noteFinaleToText(note){
        switch (true){
            case note<=4:
                return LayoutVars.translations["Quizz note finale 1"];
            case note<=6:
                return LayoutVars.translations["Quizz note finale 2"];
            case note<=8:
                return LayoutVars.translations["Quizz note finale 3"];
            default:
                return LayoutVars.translations["Quizz note finale 4"];
        }
    }

    static initFromDom(){
        $("[data-quizz='']").each(function(){
            $(this).attr("data-quizz","init");
            new Quizz($(this));
        });
    }
}